import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "toc-authorization-and-security"
    }}><h2 {...{
        "id": "authorization-and-security",
        "parentName": "div"
      }}>{`Authorization and Security`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The HTTP `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Authorization`}</inlineCode>
        {` header is required for authorization. The username is
your
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/license-key",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`MaxMind account ID`}</a>
        {`.
The password is your
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/license-key",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`MaxMind license key`}</a>
        {`.`}</p>
      <Alert type="warning" mdxType="Alert">
        <p>{`You must be approved for a `}
          <a {...{
            "href": "https://www.maxmind.com/en/request-service-trial?service_geoip=1",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoIP2 web service
trial`}</a>
          {`,
`}
          <a {...{
            "href": "https://www.maxmind.com/en/geoip2-precision-insights",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`purchase service credit`}</a>
          {`
for use with our web services, or have a `}
          <a {...{
            "href": "https://www.maxmind.com/en/geolite2/signup",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`GeoLite2
account`}</a>
          {`, in order to receive an
account ID and license key.`}</p>
      </Alert>
      <p {...{
        "parentName": "div"
      }}>{`We use
`}
        <a {...{
          "href": "https://en.wikipedia.org/wiki/Basic_access_authentication",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`basic HTTP authentication`}</a>
        {`.
The APIs which require authentication are only available via HTTPS. The
credentials are never transmitted unencrypted. If you attempt to access this
service via HTTP, you will receive a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`403 Forbidden`}</inlineCode>
        {` HTTP response.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`We require TLS 1.2 or greater for all requests to our servers to keep your data
secure.`}</p></div>
    <div {...{
      "id": "toc-service-endpoints"
    }}><h2 {...{
        "id": "service-endpoints",
        "parentName": "div"
      }}>{`Service Endpoints`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The endpoint for each service is as specified below. Each endpoint expects an IP
adddress to be defined as a path parameter (`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`{ip_address}`}</inlineCode>
        {`).`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The IP address can be either an IPv4 or an IPv6 address. IPv4 addresses should
be passed in the standard dotted quad form, for example `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`1.2.3.4`}</inlineCode>
        {`. IPv6
addresses should be passed as strings as well. We recommend using the canonical
form as described in `}
        <a {...{
          "href": "https://tools.ietf.org/html/rfc5952",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`RFC 5952`}</a>
        {`, for
example `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`2001:db8::1:0:0:1`}</inlineCode>
        {`, but we will handle any valid IPv6 string
representation.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`You can also use the string `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`me`}</inlineCode>
        {` as the IP address. In this case, the record for
the IP address you are querying from will be returned. This is useful when your
application does not have easy access to its public IP address, e.g., when the
system making the query is behind a NAT.`}</p>
      <div {...{
        "id": "toc-geoip2-endpoints",
        "parentName": "div"
      }}><h3 {...{
          "id": "geoip2-endpoints",
          "parentName": "div"
        }}>{`GeoIP2 Endpoints`}</h3>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Service`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`HTTP Method`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`Endpoint`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Country`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`GET`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v2.1/country/{ip_address}`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`City Plus`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`GET`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v2.1/city/{ip_address}`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Insights`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`GET`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v2.1/insights/{ip_address}`}</inlineCode></td>

            </tr>

          </tbody>

        </table>
        <p {...{
          "parentName": "div"
        }}>{`The `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip.maxmind.com`}</inlineCode>
          {` hostname automatically picks the data center
geographically closest to you.`}</p></div>
      <div {...{
        "id": "toc-geolite2-endpoints",
        "parentName": "div"
      }}><h3 {...{
          "id": "geolite2-endpoints",
          "parentName": "div"
        }}>{`GeoLite2 Endpoints`}</h3>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Service`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`HTTP Method`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`Endpoint`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Country`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`GET`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geolite.info/geoip/v2.1/country/{ip_address}`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`City`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`GET`}</inlineCode></td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geolite.info/geoip/v2.1/city/{ip_address}`}</inlineCode></td>

            </tr>

          </tbody>

        </table>
        <p {...{
          "parentName": "div"
        }}>{`The `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geolite.info`}</inlineCode>
          {` hostname automatically picks the data center geographically
closest to you.`}</p></div></div>
    <div {...{
      "id": "toc-headers"
    }}><h2 {...{
        "id": "headers",
        "parentName": "div"
      }}>{`Headers`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Authorization`}</inlineCode>
        {` header is always required. See
`}
        <a {...{
          "href": "#authorization-and-security",
          "parentName": "p"
        }}>{`Authorization and Security`}</a>
        {` for more details.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Accept`}</inlineCode>
        {` header for a request is entirely optional. If you do include one,
you must accept one of the following:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`application/json`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`application/vnd.maxmind.com-country+json`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`application/vnd.maxmind.com-country+json; charset=UTF-8; version=2.1`}</inlineCode></li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`Substitute the appropriate service's type for "country". A request for any other
MIME type will result in a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`415 Unsupported Media Type`}</inlineCode>
        {` error.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If you set the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Accept-Charset`}</inlineCode>
        {` header in your client code, you must accept the
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`UTF-8`}</inlineCode>
        {` character set. If you don't you will receive a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`406 Not Acceptable`}</inlineCode>
        {`
response.`}</p></div>
    <div {...{
      "id": "toc-troubleshooting-ip-lookups"
    }}><h2 {...{
        "id": "troubleshooting-ip-lookups",
        "parentName": "div"
      }}>{`Troubleshooting IP Lookups`}</h2>
      <p {...{
        "parentName": "div"
      }}><a {...{
          "href": "https://support.maxmind.com/hc/en-us/articles/4408248823835-Optimize-my-Web-Service-Integration",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Learn about common troubleshooting steps to make sure that you're querying the correct IP addresses, and making efficient use of your queries, on our Knowledge Base.`}</a></p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      